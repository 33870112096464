import Accordion from "../common/Accordion";

const Introduction = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Joseph Maruca"}
      id="joseph"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        Hello! I'm a designer based out of New York with a focus on visual
        identity, design systems and brand storytelling with over 10 years
        experience. Working with some great studios and clients over the years,
        I've helped craft concepts and designs for a wide range of industries
        and causes with the help of many talented people around me. Currently
        taking on projects, collaborations and opportunities. Gimme a shout!
        <a href="mailto:josephmaruca@gmail.com">josephmaruca@gmail.com</a>
      </p>

      <div className="mt-14 max-md:mt-4">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-8 max-md:gap-4">
          <div className="col-span-1">
            <h2>Experience</h2>
            <p>
              2018-Present&nbsp;
              <i>Freelance Design & Design Direction</i>
            </p>
            <p>
              2013-2018 Prophet&nbsp;
              <i>Senior Designer</i>
            </p>
            <p>
              2012-2013 aruliden&nbsp;
              <i>Freelance Designer</i>
            </p>
            <p>
              2011-2012 Warehouse Agency&nbsp;
              <i>Junior Designer</i>
            </p>
            <p>
              2011 Dress Code&nbsp;
              <i>Design Intern</i>
            </p>
            <p>
              2010 Lifelong Friendship Society&nbsp;
              <i>Design Intern</i>
            </p>
          </div>

          <div className="col-span-1">
            <h2>Collaboration</h2>
            <p>
              Hugo Collective, Prosek Partners, Grizzly, Red Antler, Hyperakt
            </p>
            <h2 className="mt-4">Education</h2>
            <p>
              Pratt Institute&nbsp;
              <i>2011 BA Communication Design</i>
            </p>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default Introduction;
