import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const Vertice = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Vertice"}
      id="vertice"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        The goal of Vertice was to create a brand that fits into a category of
        one, bringing together their signature, elevated hospitality experiences
        and world-class team. We wanted a look and feel that could embody
        various ventures and experiences from SingleThread Farms—a three
        Michelin star restaurant and boutique hotel in California—to Remsen, a
        luxury destination hotel in Brooklyn. The wordmark is constructed using
        a modern serif with sharp angles and cuts within the letterforms. The
        highlight of the logo is the monogram “V” that only uses one diagonal
        bar and one horizontal bar to create the “V” shape. A combination of
        dark and bright greens are used as accents throughout the system and are
        rooted in Vertice’s history of farming and commitment to unmatched
        expertise.
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-animation-2_4.gif"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_02.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_03.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_04.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_13.jpg"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_10.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_11.jpg"
            className="w-[50%] h-full object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_06.jpg"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_12.png"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_07.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/vertice-casestudy_05.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default Vertice;
