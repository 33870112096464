import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const LogosandMarks = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Logos and Marks"}
      id="logosand"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <div className="p-5">
        <p></p>

        <div className="flex flex-col">
          <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
              alt="..."
              src="/img/logo_caliga.svg"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
              alt="..."
              src="/img/logo_csc.svg"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
              alt="..."
              src="/img/logo_qdoba.svg"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
              alt="..."
              src="/img/logo_unefon.svg"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="py-4 w-full max-md:py-3 max-sm:py-2">
            <LazyLoadImage
              alt="..."
              src="/img/logo_wmm.svg"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default LogosandMarks;
