import { useEffect, useState } from "react";
import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const MetLife = ({
    setElementScrollTop,
    clickedBtn,
    setClickedBtn,
    visibility,
    setVisibility,
}) => {
    const [mLnew, setMLnew] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setMLnew("/img/ML_new.mp4");
        }, 2000);
    }, []);

    return (
        <Accordion
            title={"MetLife"}
            id="metlife"
            clickedBtn={clickedBtn}
            setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
            visibility={visibility}
            setVisibility={(visibility) => setVisibility(visibility)}
            setElementScrollTop={(offset) => setElementScrollTop(offset)}
        >
            <p>
                As MetLife underwent a period of economic and technological
                change, they sought to create a new identity that reflected
                forward-looking strategy. We developed the partnership “M”
                monogram to represent the meeting point of two things. The
                overlay in the "M" then developed into the filmstrip system that
                allowed for storytelling across all communications and business
                units.Following the visual identity rollout, the team was tasked
                to develop environmental branding throughout MetLife offices
                around the globe. Modern, Optimistic, Simple, and Tells a Story
                were the characteristics used in all the visuals to be
                considered on-brand. The wall coverings were grouped into four
                collections that work together to tell different aspects of the
                MetLife story: Core Brand, Heritage, Global Reach and Science of
                Understanding.
                <br />
                <font color="#D3D3D3">
                    Developed by Prophet w/Craig Stout, Donna Hadfield & Marissa
                    Molinaro
                </font>
            </p>

            <div className="flex flex-col">
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_00.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_logo.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_14.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_img2.jpg"
                        className="w-[50%] h-full float-right object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_03.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_11.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_12.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_04.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_img4.png"
                        className="w-[50%] h-full float-left object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_01.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_05.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_06.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_img7.png"
                        className="w-[50%] h-full float-right object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_07.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/metlife_casestudy_web_08.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full  max-md:py-3 max-sm:py-2">
                    <video
                        src={mLnew}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
            </div>
        </Accordion>
    );
};

export default MetLife;
