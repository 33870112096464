import { useEffect, useState } from "react";
import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const WainwrightRealty = ({
    setElementScrollTop,
    clickedBtn,
    setClickedBtn,
    visibility,
    setVisibility,
}) => {
    const [wain, setWain] = useState("");
    const [wainSocial, setWainSocial] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setWain("/img/wainwright colors 2.mp4");
            setWainSocial("/img/wainwright_social.mp4");
        }, 2000);
    }, []);

    return (
        <Accordion
            title={"Wainwright Realty"}
            id="wainwright"
            clickedBtn={clickedBtn}
            setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
            visibility={visibility}
            setVisibility={(visibility) => setVisibility(visibility)}
            setElementScrollTop={(offset) => setElementScrollTop(offset)}
        >
            <p>
                Wainwright is a Detroit-based real estate brokerage focused on
                residential and commercial sales and leasing, bringing the
                highest level of service to all 143-square miles of the city as
                well as the entire state of Michigan. The identity revolves
                around a circular symbol crafted from bold lines to represent
                Wainwright’s commitment to help navigate the home-buying
                process. The symbol also suggests an abstract wagon wheel, which
                is inspired by the unique configuration of downtown Detroit. The
                simple color palette uses a dominate bright blue across the
                identity alongside black, white, and grey—making the visual
                identity hard to miss whether it’s a "For Sale" sign in the
                suburbs or on a tote bag downtown.
            </p>

            <div className="flex flex-col">
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright.gif"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/detroits-gridss.jpg"
                        className="w-[50%] h-full object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={wain}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_08.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_07.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_12.jpg"
                        className="w-[60%] h-full float-right object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_03.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <video
                        src={wainSocial}
                        className="w-full h-full object-cover"
                        autoPlay
                        muted
                        loop
                        playsInline
                    ></video>
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_15.jpg"
                        className="w-[50%] h-full float-left object-cover max-md:w-full"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_02.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_11.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="py-4 w-full max-md:py-3 max-sm:py-2">
                    <LazyLoadImage
                        alt="..."
                        src="/img/wainwright-casestudy_06.jpg"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>
        </Accordion>
    );
};

export default WainwrightRealty;
