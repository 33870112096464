import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const TheWhartonCenter = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"The Wharton Center"}
      id="wharton"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        Proposed identity for The Wharton Center, Michigan State University’s
        performing arts center. The primary mark of the identity is inspired by
        the shape of the building, the audience gallery and a smile. All three
        aspects play a key role in the experience of seeing a performance at The
        Wharton Center. Building on this idea, the simple mark turned into
        dynamic patterns and graphics to emulate dance, theater, music, and all
        things performance.
        <br />
        <font color="#D3D3D3">Developed by Prophet w/Craig Stout</font>
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/Cobb_Great_Hall_seats.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/Wharton_Center.jpg"
            className="w-[50%] h-full object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/whartoncenter_casestudy_web1.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/Wharton_poster.png"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/whartoncenter_casestudy_web4.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/whartoncenter_casestudyweb6.jpg"
            className="w-[50%] h-full object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/whartoncenter_casestudy_web5.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/wharton.gif"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/whartoncenter_casestudy_web3.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/whartoncenter_casestudy_web.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default TheWhartonCenter;
