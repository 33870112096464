import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const BowieFilm = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Bowie in Film"}
      id={"bowieinfilm"}
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        Limited edition screen-printed poster for immersive film series looking
        at Bowie's career in film as part of 'Bowie Is' exhibiton at The
        Brooklyn Museum. Poster highlights David Bowies first starring film role
        in "The Man Who Fell to Earth" directed by Nicolas Roeg. Poster was
        created in collaboration with{" "}
        <a href="http://littlecinema.net">Little Cinema</a>.
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/db_casestudy_web-04.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/db_casestudy_web-08.jpg"
            className="w-[50%] h-full float-left object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/db_casestudy_web-05.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/db_casestudy_web-03.jpg"
            className="w-[50%] h-full float-right object-cover max-md:w-full"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/db_casestudy_web-06.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default BowieFilm;
