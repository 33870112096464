import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const MuseumfurGestaltung = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Museum für Gestaltung"}
      id="museum"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        During the summer of 2011, I was lucky enough to participate in the
        typography summer workshop at the Basel School of Design. This was a
        proposed poster for the 'Type in Motion Exhibition' at the Museum für
        Gestaltung.
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/furgestaltung.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default MuseumfurGestaltung;
