import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const WallAll = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Wall/All"}
      id="wallall"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        In 2015, I was asked to create a graphic illustration as part of a
        collection of artists for an event called Immigrant Neighbors. The
        postcards would be handed out to raise money to benefit the important
        work of the Legal Aid Society Immigration Law Unit. My message was a
        simple: Protest against the border wall proposed by the 45th President
        of the United States. After the event, hand screen-printed bags were
        produced and handed out to the public.
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/wall_casestudy_web-01.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/wall_casestudy_web-02.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/wall_casestudy_web-03.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/wall_casestudy_web-04.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default WallAll;
