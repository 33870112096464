import React from "react";
import jQuery from "jquery";

const Accordion = ({
    title,
    children,
    id,
    visibility,
    setVisibility,
    clickedBtn,
    setClickedBtn,
    setElementScrollTop,
}) => {
    const contentRef = React.useRef();

    React.useEffect(() => {
        const el = contentRef.current;
        const height = el.scrollHeight + 600;
        el.style.setProperty("--max-height", height + "px");
        if (visibility) {
            el.classList.add("p-5");
        } else {
            if (!clickedBtn) {
                el.classList.remove("p-5");
            } else {
                setTimeout(() => {
                    el.classList.remove("p-5");
                }, 300);
            }
        }
    }, [visibility, clickedBtn]);

    const onScrollTo = () => {
        setTimeout(() => {
            jQuery("html, body").animate({
                scrollTop: jQuery(`#${id}`).offset().top,
            });
            setElementScrollTop(
                visibility === false ? jQuery(`#${id}`).offset().top : 0
            );
        }, 700);
    };

    return (
        <div className="accordion" id={id}>
            <button
                className={`accordion__button w-full bg-white flex justify-between cursor-pointer border-b border-[#111111] hover:line-through hover:decoration-2 p-5 relative focus:outline-none ${
                    visibility ? "active" : ""
                }`}
                onClick={() => {
                    setVisibility(!visibility);
                    setClickedBtn(true);
                    onScrollTo();
                }}
            >
                {title}
            </button>
            <div
                ref={contentRef}
                className={`accordion__content ${
                    visibility ? "active" : "p-5"
                }`}
            >
                {children}
            </div>
        </div>
    );
};

export default Accordion;
