import Accordion from "../common/Accordion";
import LazyLoadImage from "../common/LazyLoadImage";

const StudioVK = ({
  setElementScrollTop,
  clickedBtn,
  setClickedBtn,
  visibility,
  setVisibility,
}) => {
  return (
    <Accordion
      title={"Studio VK"}
      id="studiovk"
      clickedBtn={clickedBtn}
      setClickedBtn={(clickedBtn) => setClickedBtn(clickedBtn)}
      visibility={visibility}
      setVisibility={(visibility) => setVisibility(visibility)}
      setElementScrollTop={(offset) => setElementScrollTop(offset)}
    >
      <p>
        Studio VK is an architectural and interior design studio founded by
        Veronica Kan in 2016. The visual identity is simple and clear, giving
        primary focus to the typography that is built on symmetrical balance. A
        subtle tie-back to one of the basic rules of architecture.
      </p>

      <div className="flex flex-col">
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/svk_casestudy_web-01.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/svk_casestudy_web-02.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/svk_casestudy_web-03.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/svk_casestudy_web-04.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="py-4 w-full max-md:py-3 max-sm:py-2">
          <LazyLoadImage
            alt="..."
            src="/img/svk_casestudy_web-05.jpg"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </Accordion>
  );
};

export default StudioVK;
